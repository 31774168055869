<template>
  <div class="services">
    <div class="vertical-title">Services</div>
    <section class="services-introduction">
      <h1 class="services-introduction-1">{{ $t("services-introduction-1") }}</h1>
      <h1 class="text-center services-introduction-2">{{ $t("services-introduction-2") }}</h1>
       <p class="services-introduction-3 text-center">{{ $t("services-introduction-3") }}</p>
    </section>
    <section class="services-detail" id="services-detail">
      <h2 class="services-detail-title">{{ $t("services-detail-title")}}</h2>
      <div class="services-detail-services">
        <div class="services-card" v-for="item in Array(4).keys()" :key="item">
          <h4 class="services-card-title">{{ item + 1 }}. {{ $t(`services-${item + 1}-title`) }}</h4>
          <div class="services-card-description" v-html="$t(`services-${item + 1}-description`)"></div>
        </div>
      </div>
    </section>
    <section class="services-customers">
      <h2>{{ $t("home-customers") }}</h2>
      <div class="icon-links">
        <a v-for="item in customers" :key="item.name" :href="item.link" target="_blank">
          <img :src="require(`../assets/logo/customers/${item.img}`)" :alt="item.name" />
        </a>
      </div>
    </section>
    <div class="vertical-text geo">{{ $t("vertical-geo") }}</div>
  </div>
</template>

<script>
import customers from '../data/customers'
export default {
  props: ['lang'],
  data () {
    return {
      customers
    }
  },
  mounted () {
    if (this.lang) {
      this.$i18n.locale = this.lang
    }
  }
}
</script>

<style lang="scss">
.services {
  background-image: url("../assets/images/services-background-1.png"),
    url("../assets/images/services-background-2.png"),
    url("../assets/images/services-background-4.png"),
    url("../assets/images/services-background-3.png");
  background-repeat: no-repeat;
  background-position: right top, 600px 840px, 0px 1200px, left 900px;
  .vertical-title {
    top: 340px;
  }
  &-introduction {
    &-1 {
      padding-top: 304px;
      max-width: 550px;
      font-size: 82px;
    }
    &-2 {
      padding-top: 460px;
      max-width: 400px;
    }
    &-3 {
      font-size: 18px;
      max-width: 400px;
      line-height: 1.89;
    }
  }
  &-detail {
    &-services {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      @media (min-width: 1280px) {
          max-height: 1400px;
      }
      .services-card {
        text-align: left;
        max-width: 500px;
        background-color: #1c1c26;
        padding: 36px 36px 0 40px;
        margin-right: 60px;
        margin-bottom: 57px;
        &-title {
          font-family: "geomanist-medium";
          font-size: 36px;
          font-weight: 500;
          line-height: 1.22;
          color: #ffba4e;
          padding-bottom: 30px;
        }
        &-description {
          font-family: "geomanist-book";
          font-size: 18px;
          line-height: 1.72;
          color: #63636b;
          ul {
            line-height: 1.5;
            font-size: 20px;
            li {
              list-style-image: url("../assets/icons/stroke.svg");
              padding-left: 15px;
            }
          }
        }
      }
    }
  }
  .geo {
    right: -360px;
    top: 1240px;
  }
}
@media (max-width: 1024px) {
  .services {
    background-image: url("../assets/images/services-background-1-small.png"),
      url("../assets/images/services-background-2-small.png"),
      url("../assets/images/services-background-4.png"),
      url("../assets/images/services-background-3.png");
    background-repeat: no-repeat;
    background-position: right top, left 650px, 0px 1200px, left 900px;
    &-introduction {
      &-1 {
        text-align: left;
        padding-top: 200px;
        max-width: 275px;
        font-size: 2.5rem;
        line-height: 2.75rem;
      }
      &-2 {
        padding-top: 420px;
        text-align: left;
        max-width: 325px;
      }
      &-3 {
        text-align: left;
        max-width: 325px;
      }
    }
    .services-detail {
      margin: 20px
    }
    .services-card {
      margin: 60px 0;
      padding: 30px;
    }
    .geo {
      right: -160px;
      top: 650px;
    }
  }
}
</style>
