<template>
  <span id="lang">
    <a
      @click="recordLang($t('other-lang-path'));"
      :href="`/${$t('other-lang-path')}/${pageId}`"
    >{{ $t("other-lang-label") }}</a>
  </span>
</template>

<script>
export default {
  name: 'locale-changer',
  data () {
    return {
      activeLang: this.$i18n.locale
    }
  },
  computed: {
    pageId () {
      return ['home', 'article-fr', 'article-en'].includes(this.$route.name) ? '' : this.$route.fullPath.replace(`/${this.activeLang}/`, '')
    }
  },
  methods: {
    recordLang: function (menuItem) {
      window.localStorage.setItem('lang', menuItem)
    }
  }
}
</script>
