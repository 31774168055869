var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blog-page"},[_c('div',{staticClass:"blog"},[_c('div',{staticClass:"blog__header"},[_vm._m(0),_c('div',{staticClass:"blog__header__end"},[_c('form',{staticClass:"blog-form",attrs:{"action":"https://eepurl.com/dumt8f","method":"post","name":"mc-embedded-subscribe-form","target":"_blank","novalidate":""}},[_c('input',{attrs:{"id":"email-input","type":"email","name":"EMAIL","placeholder":_vm.$t('home-contact-email-placeholder'),"required":""}}),_vm._m(1),_c('button',{attrs:{"type":"submit","id":"email-validate","value":"Subscribe"}},[_vm._v(" Valider ")])]),_c('div',{staticClass:"blog-select"},[_c('custom-select',{attrs:{"options":[
              { value: null, label: 'Toutes Les catégories' } ].concat( _vm.categories.map(function (ref) {
                          var value = ref.id;
                          var label = ref.name;

                          return ({
                value: value,
                label: label
              });
})
            )},model:{value:(_vm.currentCategory),callback:function ($$v) {_vm.currentCategory=$$v},expression:"currentCategory"}})],1)])]),_c('div',{staticClass:"blog__content"},[_vm._l((_vm.visiblePosts),function(item,index){return _c('a',{key:index,staticClass:"blog-card",attrs:{"href":("/" + (_vm.$i18n.locale) + "/articles/" + (item.slug))}},[_c('div',{staticClass:"blog-card__img"},[_c('img',{attrs:{"src":_vm.getImageUrl(item),"alt":"","height":"100%"}})]),_c('div',{staticClass:"blog-card__content"},[_c('div',{staticClass:"blog-card__content__category"},[_vm._v(" "+_vm._s(_vm.getCategory(item))+" ")]),_c('div',{staticClass:"blog-card__content__title"},[_c('h3',{domProps:{"innerHTML":_vm._s(item.title.rendered)}})]),_c('div',{staticClass:"blog-card__content__excerpt"},[_c('span',{domProps:{"innerHTML":_vm._s(item.excerpt.rendered)}}),_vm._v(" ... more ")])])])}),_c('a',{staticClass:"blog-card",attrs:{"href":("/" + (_vm.$i18n.locale) + "/anagraph-hebdo")}},[_c('div',{staticClass:"blog-card__img"},[_c('img',{attrs:{"src":'http://blog.anagraph.io/wp-content/uploads/2021/06/Anagraph-Hebdo.png',"alt":"","height":"100%"}})]),_vm._m(2)])],2),_c('div',{staticClass:"blog__more"},[(_vm.posts.length > 5 && _vm.limitedResults)?_c('div',{staticClass:"button-more",on:{"click":function($event){_vm.limitedResults = false}}},[_vm._v(" afficher tout ")]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blog__header__start"},[_c('div',{staticClass:"blog-title"},[_c('h1',[_vm._v("Blog")])]),_c('p',{staticClass:"blog-description"},[_vm._v(" Réflexions, technologies et astuces — votre dose de nouveautés en géospatial. Abonnez-vous à notre infolettre pour ne rien manquer: ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"absolute","left":"-5000px"},attrs:{"aria-hidden":"true"}},[_c('input',{attrs:{"type":"text","name":"b_fe6ec1bc5cb0f149502b7ec51_f0402d324c","tabindex":"-1","value":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blog-card__content"},[_c('div',{staticClass:"blog-card__content__category"},[_vm._v(" Misc ")]),_c('div',{staticClass:"blog-card__content__title"},[_c('h3',[_vm._v("Anagraph Hebdo")])]),_c('div',{staticClass:"blog-card__content__excerpt"},[_vm._v(" Chaque semaine, nos liens favoris ")])])}]

export { render, staticRenderFns }