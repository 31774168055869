<template>
  <div class="home">
    <h1 class="vertical-title">Anagraph</h1>
    <section class="home-introduction">
      <h1>{{ $t("home-introduction-1") }}</h1>
      <button class="button-mobile home-introduction-projects-link">
        <a :href="`#home-projects`">{{ $t("home-see-our-projects") }}</a>
      </button>
      <div class="text-center home-introduction-content">
        <h2>{{ $t("home-introduction-2") }}</h2>
        <a
          class="page-link home-introduction-services-link-a"
          :href="`/${$i18n.locale}/services`"
          style="font-family: 'geomanist-bold' "
        >{{ $t("home-introduction-link") }}</a>
        <button class="button-mobile home-introduction-services-link-button" style="margin-top: 0px">
          <a :href="`/${$i18n.locale}/services`">{{ $t("home-introduction-link") }}</a>
        </button>
      </div>
    </section>
    <section class="home-customers">
      <h2>{{ $t("home-customers") }}</h2>
      <div class="icon-links">
        <a v-for="item in customers" :key="item.name" :href="item.link" target="_blank">
          <img :src="require(`../assets/logo/customers/${item.img}`)" :alt="item.name" />
        </a>
      </div>
    </section>
    <section class="home-projects" id="home-projects">
      <h2>{{ $t("home-projects") }}</h2>
      <div class="home-projects-tiles">
        <div class="tile projects" v-for="item in projects" :key="item.id">
          <a :href="item.link" target="_blank">
            <img :src="require(`../assets/images/projects/${item.image}`)" :alt="item.id" />
            <label class="page-link">{{ item.name }}</label>
            <div class="overlay"></div>
          </a>
        </div>
      </div>
    </section>
    <section class="home-news" id="home-news" v-if="news.length">
      <h2>{{ $t("home-news") }}</h2>
      <div class="home-news-tiles">
        <div class="tile" v-for="item in news" :key="item.id">
          <router-link :to="`/${item.lang}/articles/${item.slug}`">
            <h3 v-html="item.title.rendered" />
            <p class="excerpt" v-html="item.excerpt.rendered" />
            <label class="page-link">{{ $t("home-read-post") }}</label>
          </router-link>
        </div>
      </div>
    </section>

    <div class="vertical-text xy">X&Y</div>
    <div class="vertical-text work">WORK</div>
  </div>
</template>

<script>
import axios from 'axios'
import customers from '../data/customers'
import projects from '../data/projects'
import categories from '../blog-data/categories'
import posts from '../blog-data/posts'
const ANAGRAPH_HEBDO_CATEGORY = 35
const PUBLISHED_CATEGORY = 42

function getNews (posts) {
  const publishedBlogPosts = posts.filter(post => post.categories.includes(PUBLISHED_CATEGORY) && !post.categories.includes(ANAGRAPH_HEBDO_CATEGORY))
  return publishedBlogPosts.slice(0, 3)
}
export default {
  name: 'Home',
  props: ['lang'],
  data () {
    return {
      customers,
      projects,
      news: [],
      posts,
      categories
    }
  },
  async mounted () {
    if (process.env.VUE_APP_PREVIEW) {
      this.posts = await axios.get('https://blog.anagraph.io/wp-json/wp/v2/posts?per_page=100').then(response => response.data)
      this.categories = await axios.get('https://blog.anagraph.io/wp-json/wp/v2/categories').then(response => response.data)
    }
    // console.log({ posts })
    if (this.lang) {
      this.$i18n.locale = this.lang
    }
    this.news = getNews(this.posts)
  }
}
</script>
<style lang="scss">
@media (max-width: 1023px) {

  .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  background-color: #1C1C26;
  mix-blend-mode: color;
  z-index: 2;
  cursor: pointer;
  }

  .xy {
    right: -105px;
    top: 533px;
  }
  .work {
    display: none;
  }

  .home-video {
  video {
    display: none;
    }
  }

  .home {
    &-introduction {
      padding-top: 140px;
      .page-link {
        font-family: "geomanist-medium", Helvetica, Arial, sans-serif;
        font-size: 1rem;
        letter-spacing: 0.5px;
        text-align: center;
        font-weight: 500;
        border-style: solid;
        border-width: 2px;
        border-radius: 28px;
        background-color: #ffba4e;
        color: black;
        padding: 16px 40px 14px 40px;
      }
      .page-link::after {
        content: none;
      }
      &-services-link-a {
        display: none;
      }
      &-content {
        margin-top: 200px;
        h2 {
          font-size: 1rem;
          font-family: "geomanist-book";
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.63;
          letter-spacing: 0.1px;
          color: #c2c2c8;
        }
      }
    }
    &-customers {
      margin-bottom: 0px;
    }
    &-projects {
      &-tiles {
        display: flex;
        flex-direction: column;
        .tile {
          margin: 25px 0px;
          position: relative;
          img {
            width: 100%;
            margin-bottom: -10px;
          }
          label {
            position: absolute;
            bottom: 15px;
            left: 30px;
            cursor: pointer;
            z-index: 3;
          }
        }
        .tile:hover {
          img{
            filter: none !important;
          }
        }
      }
    }
    &-news {
      &-tiles {
        display: flex;
        flex-direction: column;
        .tile {
          margin: 25px 0px;
          position: relative;
          img {
            width: 100%;
            margin-bottom: -10px;
          }
          label {
            position: absolute;
            bottom: 15px;
            right: 30px;
            cursor: pointer;
            z-index: 3;
          }
        }
        .tile:hover {
          img{
            filter: none !important;
          }
        }
      }
    }
  }
}
@media (min-width: 1023px) {
  .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  background-color: #1C1C26;
  mix-blend-mode: color;
  z-index: 2;
  cursor: pointer;
}

.home-video {
  position: absolute;
  width: 50%;
  height: 80vh;
  margin-left: 650px;
  margin-top: -650px;
  video {
    height: 100%;
  }
}

  .projects:hover .overlay {
  opacity: 0;
  }
  .xy {
    right: -235px;
    top: 1350px;
  }
  .work {
    right: -470px;
    z-index: -1;
    top: 3095px;
    &.fr {
      top: 3128px;
      right: -650px;
    }
  }
  .home {
    background-image: url("../assets/images/home-background-1.gif"),
      url("../assets/images/home-background-2.png");
    background-repeat: no-repeat;
    background-position: right top, left 1049px;
    background-size: 50%, 20%;
    .vertical-title {
      top: 388px;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    &-introduction {
      padding-top: 437px;
      h1 {
        width: 530px;
      }
      &-projects-link {
        display: none;
      }
      &-content {
        padding: 310px 0;
        h2 {
          font-family: "geomanist-bold";
          width: 635px;
          font-weight: bold;
          letter-spacing: normal;
          color: #ffffff;
          padding: 0;
          margin-bottom: 30px;
        }
      }
    }
    &-customers {
      margin-bottom: 136px;
    }
    &-projects, &-news {
      &-tiles {
        display: grid;
        grid-template-columns: auto auto;
        @media (max-width: 1440px) {
          grid-template-columns: auto;
        }
        grid-gap: 60px;
        .tile {
          background-color: #1c1c26;
          position: relative;
          img {
            width: 100%;
            margin-bottom: -10px;
          }
          label {
            position: absolute;
            bottom: 15px;
            /* left: 30px; */
            cursor: pointer;
            z-index: 3;
          }
        }
        .tile:hover {
          img{
            filter: none !important;
          }
        }
      }
    }
    &-projects {
      label {
        left: 30px;
      }
    }
    &-news {
      .tile {
        padding: 30px;
      }
      h3 {
        font-family: "geomanist-medium";
        font-weight: 500;
      }
      .excerpt {
        font-family: "geomanist-book";
        color: #63636b;
        font-size: 18px;
        padding: 0;
      }
      label {
        right: 30px;
      }
    }
  }
}
</style>
