import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Services from '../views/Services.vue'
import Blog from '../views/Blog.vue'
import Hebdo from '../views/Hebdo.vue'
import Careers from '../views/Careers.vue'
import Article from '../views/Article.vue'
import Job from '../views/Job.vue'
import { scrollTo } from 'vue-scrollto'
import langMessages from '../messages.json'
import posts from '@/blog-data/posts.json'
import media from '@/blog-data/media.json'

Vue.use(VueRouter)

const scrollBehavior = function (to, from, savedPosition) {
  if (from.name === to.name && to.hash !== '') {
    const options = {
      container: 'body',
      easing: 'ease-in',
      force: true,
      cancelable: true,
      x: false,
      y: true
    }
    scrollTo(to.hash, 400, options)
  }
  if (savedPosition) {
    return savedPosition
  }
  const position = {}
  if (!to.hash) {
    position.x = 0
    position.y = 0
  } else {
    position.selector = to.hash
  }
  return position
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { scrollToTop: false }
  },
  {
    path: '/fr',
    name: 'home-fr',
    component: Home,
    meta: { scrollToTop: true, lang: 'fr' },
    props: { lang: 'fr' }
  },
  {
    path: '/en',
    name: 'home-en',
    component: Home,
    meta: { scrollToTop: true, lang: 'en' },
    props: { lang: 'en' }
  },
  {
    path: '/en/about',
    name: 'about-en',
    component: About,
    meta: { scrollToTop: false, lang: 'en' },
    props: { lang: 'en' }
  },
  {
    path: '/fr/about',
    name: 'about-fr',
    component: About,
    meta: { scrollToTop: true, lang: 'fr' },
    props: { lang: 'fr' }
  },
  {
    path: '/en/services',
    name: 'services-en',
    component: Services,
    meta: { scrollToTop: true, lang: 'en' },
    props: { lang: 'en' }
  },
  {
    path: '/fr/services',
    name: 'services-fr',
    component: Services,
    meta: { scrollToTop: true, lang: 'fr' },
    props: { lang: 'fr' }
  },
  {
    path: '/en/blog',
    name: 'blog-en',
    component: Blog,
    meta: { scrollToTop: true, lang: 'en' },
    props: { lang: 'en' }
  },
  {
    path: '/fr/blog',
    name: 'blog-fr',
    component: Blog,
    meta: { scrollToTop: true, lang: 'fr' },
    props: { lang: 'fr' }
  },
  {
    path: '/en/anagraph-hebdo',
    name: 'hebdo-en',
    component: Hebdo,
    meta: { scrollToTop: true, lang: 'en' },
    props: { lang: 'en' }
  },
  {
    path: '/fr/anagraph-hebdo',
    name: 'hebdo-fr',
    component: Hebdo,
    meta: { scrollToTop: true, lang: 'fr' },
    props: { lang: 'fr' }
  },
  {
    path: '/en/careers',
    name: 'careers-en',
    component: Careers,
    meta: { scrollToTop: true, lang: 'en' },
    props: { lang: 'en' }
  },
  {
    path: '/fr/careers',
    name: 'careers-fr',
    component: Careers,
    meta: { scrollToTop: true, lang: 'fr' },
    props: { lang: 'fr' }
  },
  {
    path: '/fr/articles/:slug',
    name: 'article-fr',
    component: Article,
    meta: { scrollToTop: true, lang: 'fr' },
    props: { lang: 'fr' }
  },
  {
    path: '/en/articles/:slug',
    name: 'article-en',
    component: Article,
    meta: { scrollToTop: true, lang: 'en' },
    props: { lang: 'en' }
  },
  {
    path: '/fr/career/:slug',
    name: 'job-fr',
    component: Job,
    meta: { scrollToTop: true, lang: 'fr' },
    props: { lang: 'fr' }
  },
  {
    path: '/en/career/:slug',
    name: 'job-en',
    component: Job,
    meta: { scrollToTop: true, lang: 'en' },
    props: { lang: 'en' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes
})

function getPageMeta (messages) {
  return {
    title: messages['meta-title'],
    description: messages['meta-description'],
    alternateLang: messages['meta-link-alternate-href-lang'],
    alternateLink: messages['meta-link-alternate'],
    image: 'https://www.anagraph.io/anagraph-social.png',
    url: 'https://www.anagraph.io'
  }
}

function getArticleMeta (slug, lang) {
  function decodeHtml (str) {
    const p = document.createElement('p')
    p.innerHTML = str
    return p.innerText
  }
  const post = posts.find(i => i.slug === slug)
  const featureImage = post
    ? media.find(item => item.id === post.featured_media)
    : null
  const title = decodeHtml(post.title.rendered)
  return post
    ? {
      title,
      description: title,
      image: featureImage ? featureImage.guid.rendered : '',
      url: `https://www.anagraph.io/${lang}/articles/${slug}`
    }
    : {}
}

function createMetaTag (name, content) {
  const tag = document.createElement('meta')
  tag.setAttribute('name', name)
  tag.setAttribute('property', name)
  tag.setAttribute('content', content)
  tag.setAttribute('data-vue-router-controlled', '')
  document.head.appendChild(tag)
}

router.beforeEach((to, from, next) => {
  if (process.env.VUE_APP_PREVIEW) {
    next()
    return
  }
  const lang = to.meta.lang || 'en'
  document.documentElement.setAttribute('lang', lang)
  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]')
  ).map(el => el.parentNode.removeChild(el))
  const messages = langMessages[lang]
  const {
    title,
    description,
    alternateLang,
    alternateLink,
    image,
    url
  } = to.name.includes('article')
    ? getArticleMeta(to.params.slug, lang)
    : getPageMeta(messages)
  document.title = title
  const descriptionTag = document.createElement('meta')
  descriptionTag.setAttribute('name', 'description')
  descriptionTag.setAttribute('content', description)
  descriptionTag.setAttribute('data-vue-router-controlled', '')
  document.head.appendChild(descriptionTag)

  // twitter and facebook tags, cf https://css-tricks.com/essential-meta-tags-social-media/
  createMetaTag('og:title', title)
  createMetaTag('og:description', description)
  createMetaTag('og:image', image)
  createMetaTag('og:url', url)
  createMetaTag('twitter:card', 'summary_large_image')
  createMetaTag('type', 'website')

  if (alternateLink) {
    const alternate = document.createElement('link')
    alternate.setAttribute('rel', 'alternate')
    alternate.setAttribute('hreflang', alternateLang)
    alternate.setAttribute('href', alternateLink)
    alternate.setAttribute('data-vue-router-controlled', '')
    document.head.appendChild(alternate)
  }

  next()
})
export default router
