<template>
  <div id="app" data-server-rendered="true">
    <page-header/>
    <div class="page-content" :class="{light: type === 'post'}">
      <router-view/>
    </div>
    <contact v-if="type === 'page'"/>
    <page-footer/>
  </div>
</template>
<script>
import { getUserLanguage } from './lang.js'
import PageHeader from './components/Header'
import Contact from './components/Contact'
import PageFooter from './components/Footer'
export default {
  components: {
    PageHeader,
    Contact,
    PageFooter
  },
  computed: {
    type () {
      return this.$route.name.includes('article') ? 'post' : 'page'
    }
  },
  created () {
    this.$i18n.locale = getUserLanguage()
  }
}
</script>
<style lang="scss">
html {
  box-sizing: border-box;
  background-color: #20202c;
  overflow-x: hidden;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

#app {
  // overflow-y: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .page-content {
    margin: 0;
  }
  .page-content.light {
      background-color: #f4f4f4;
  }
}
</style>
