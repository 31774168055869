<template>
  <header class="header">
    <div class="header-content">
      <mobile-menu v-show="showMobileMenu" @close="toggleMobileMenu"/>
      <div class="header-content-logo">
        <router-link to="/">
          <img src="../assets/logo/anagraph.svg" class="header-content-logo-image"/>
        </router-link>
      </div>
      <nav class="header-content-nav">
        <navigation/>
      </nav>
      <div class="header-content-lang-selection"><locale-changer/></div>
      <div class="header-content-menu-mobile" @click="toggleMobileMenu">
        <img
          src="../assets/icons/burger.png"
          alt=""
        />
      </div>
    </div>
  </header>
</template>

<script>
import MobileMenu from './MobileMenu'
import Navigation from './header/Navigation'
import LocaleChanger from './header/LocaleChanger'
export default {
  components: {
    MobileMenu,
    Navigation,
    LocaleChanger
  },
  data () {
    return {
      showMobileMenu: false
    }
  },
  methods: {
    toggleMobileMenu () {
      console.log('toggle', this.showMobileMenu)
      this.showMobileMenu = !this.showMobileMenu
    }
  }
}
</script>

<style lang="scss">
.header {
  width: 100%;
  top: 0px;
  &-content {
    justify-content: space-between;
    margin: 0 auto;
    display: flex;
    align-items: center;
    &-logo {
      margin: 0;
    }
    &-nav {
      justify-content: center;
      ul {
        margin: 0;
        display: flex;
        a, a:visited {
          color: #eceaee;
          padding: 1rem 1.75rem;
          font-family: "geomanist-medium";
          &:after {
            content: '';
            height: 3px;
            background: #FFBA4E;
            display:block;
            width: 75%;
            margin-left: 12.5%;
            opacity: 0;
          }
        }
        a:hover, a.header-page-link.router-link-active {
          color: white;
          &:after {
            opacity: 1
          }
        }
      }
    }
    &-lang-selection {
      justify-content: center;
      a {
        font-family: "geomanist-medium";
        font-size: 13px;
        color: #FFBA4E;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 28px 32px;
    &-content {
      &-nav {
        display: none;
      }
      &-lang-selection {
        display: none;
      }
      &-menu-mobile{
        img {
          width: 24px;
          height: 24px;
        }
      }
    }

  }
  @media (min-width: 1024px) {
    padding: 52px 57px;
    &-content {
      width: 910px;
      &-menu-mobile {
        display: none;
      }
    }
  }
  @media (min-width: 1440px) {
    padding: 55px 53px;
    &-content {
      width: 1326px;
    }
  }
}
</style>
