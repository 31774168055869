<template>
  <div class="article-wrapper">
    <div class="article" v-if="post">
      <img :src="post.featureImageUrl" alt="" class="article-feature-image">
      <figcaption>{{ post.featureCaptionImage }}</figcaption>
      <div class="article-header">
        <h1 v-html="post.title.rendered" />
        <div class="article-header-links">
          <div class="tags">
            <li class="tag" v-for="tag in post.tags" :key="tag.id">
              {{ tag.name }}
            </li>
          </div>
          <div class="social">
            <div class="social-cta">Partagez </div>
            <div class="social-links">
              <div class="social-link" v-for="media in ['facebook', 'linkedin', 'twitter']" :key="media">
                <a :href="getShareLink(media)" target="_blank"><img :src="require(`../assets/icons/${media}.svg`)" /></a>
              </div>
            </div>
          </div>
          <div class="date"> Par {{ post.author.name }}, le {{ date }}</div>
        </div>
      </div>
      <div class="article-content" v-html="post.content.rendered"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import posts from '../blog-data/posts'
import media from '../blog-data/media'
import tags from '../blog-data/tags'
import users from '../blog-data/users'
import moment from 'moment'
import { momentLocalFr } from '../moment-fr'
moment.locale('fr', momentLocalFr)
function getPost (posts, media, tags, users, slug) {
  const post = posts.find(post => post.slug === slug)
  const featureImageId = post.featured_media
  const featureImage = media.find(item => item.id === featureImageId)
  const featureImageUrl = featureImage ? featureImage.guid.rendered : null
  const featureCaptionImage = featureImage ? featureImage.alt_text : null
  const postTags = tags.filter(tag => post.tags.includes(tag.id))
  const user = users.find(user => user.id === post.author)
  return { ...post, featureImageUrl, featureCaptionImage, tags: postTags, author: { id: user.id, name: user.name } }
}
export default {
  data () {
    return {
      posts,
      media,
      users,
      tags,
      post: null,
      date: null
    }
  },
  async mounted () {
    if (process.env.VUE_APP_PREVIEW) {
      this.posts = await axios.get('https://blog.anagraph.io/wp-json/wp/v2/posts?per_page=100').then(response => response.data)
      this.media = await axios.get('https://blog.anagraph.io/wp-json/wp/v2/media?per_page=100').then(response => response.data)
      this.tags = await axios.get('https://blog.anagraph.io/wp-json/wp/v2/tags?per_page=100').then(response => response.data)
      this.users = await axios.get('https://blog.anagraph.io/wp-json/wp/v2/users?per_page=100').then(response => response.data)
    }
    this.post = getPost(this.posts, this.media, this.tags, this.users, this.$route.params.slug)
    this.date = moment(this.post.date).format('DD MMMM YYYY')
  },
  methods: {
    getUrl () {
      const baseUrl = process.env.VUE_APP_PREVIEW ? window.location.origin : 'https://www.anagraph.io'
      return baseUrl + this.$route.path
    },
    getShareLink (network) {
      const u = encodeURIComponent(this.getUrl())
      const t = encodeURIComponent(this.post.title.rendered)
      const d = encodeURIComponent(this.post.excerpt.rendered)
      const hashtags = this.post.tags.map(tag => tag.name).join(',')
      const h = network !== 'facebook' ? encodeURIComponent(hashtags) : encodeURIComponent(hashtags.split(',')[0])
      const networks = {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${u}&title=${t}&description=${d}&hashtag=${h}`,
        twitter: `https://twitter.com/intent/tweet?text=${t}&url=${u}&hashtags=${h}`,
        linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${u}`
      }
      return networks[network]
    }
  }
}
</script>

<style lang="scss">
.article-wrapper {
  width: 100%;
}
.article {
  margin: auto;
  padding: 100px 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1023px) {
    padding: 0;
    max-width: 90%;
  }
  background-color: #f4f4f4;
  color: black;
  h1, h2, h3, h4, a {
    color: black;
  }
  a {
    text-decoration: underline;
    text-underline-offset: 2px;
    /* color: #af761b; */
    text-decoration-color: black;
    &:hover {
      /* text-decoration-color: #FDBB4E; */
      /* text-decoration: underline; */
    }
  }
  &-feature-image {
    max-width: 1024px;
    @media (max-width: 1023px) {
      max-width: 90vw;
    }
    margin-bottom: 36px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    max-width: 1024px;
    padding: 33px 0;
    @media (max-width: 1023px) {
      flex-direction: column;
      justify-content: center;
      padding: 0px 25px;
    }
    h1 {
      // max-width: 50%;
      font-family: 'geomanist-book';
      font-size: 44px;
      font-weight: bold;
      @media (max-width: 1023px) {
        font-size: 38px;
        max-width: 90%;
        text-align:  left;
      }
      padding: 0;
      line-height: 44px;
    }
    &-links {
      color: #8a8a90;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media (max-width: 1023px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        padding: 20px 0
      }
      .tags {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 17px;
        .tag {
          font-family: 'geomanist-book';
          font-size: 16px;
          color: #8a8a90;
          min-width: 72px;
          text-align: center;
          padding: 3px 12px;
          margin-left: 10px;
          margin-bottom: 12px;
          border-radius: 18px;
          border: solid 1px #8a8a90;
        }
        @media (max-width: 1023px) {
          display: none;
        }
      }
      .social {
        display: flex;
        align-items: center;
        margin-bottom: 17px;
        @media (max-width: 1023px) {
          padding: 20px 0
        }
        &-cta {
          padding: 0 10px;
          font-size: 18px;
        }
        &-links {
          display: flex;
          align-items: center;
          .social-link {
            cursor: pointer;
            width: 36px;
            height: 36px;
            margin-left: 10px;
            img {
              background-color: #ffba4e;
              padding: 10px;
              border-radius: 50%;
            }
          }
        }
      }
      .date {
        font-size: 18px;
      }
    }
  }
  &-content {
    margin: 100px;
    max-width: 1024px;
    overflow: hidden;
    @media (max-width: 1023px) {
      max-width: 90%;
      margin: 25px;
    }
    h2 {
      font-size: 28px;
      /* font-height: 28px; */
      line-height: 1.58;
      padding: 20px 0;
    }
    p, ul {
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 18px;
      line-height: 36px;
      margin: 0;
      padding: 0;
      padding-bottom: 30px;
    }
    li {
      margin-left: 20px;
    }
  }
  figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 32px 0;
    img {
      width: auto;
      max-width: 90vw;
      height: auto;
      box-shadow: 0 12px 26px 0 rgba(0, 0, 0, 0.1);
    }
    figcaption {
      padding: 20px;
      font-size: 16px;
      font-family: 'geomanist-book';
      color: #8a8a90;
    }
  }
}
</style>
