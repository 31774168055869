<template>
  <footer>
    <div class="footer">
      <div class="footer-description">
        <img src="../assets/logo/logo-only.svg" class="footer-logo" />
        <div class="footer-description-text">{{$t('footer-description')}}</div>
      </div>
      <div class="footer-others">
        <div class="footer-links">
          <nav class="footer-sitemap">
            <ul>
              <router-link :to="{ path: `/${$i18n.locale}/about` }">{{ $t('header-about') }}</router-link>
              <router-link :to="{ path: `/${$i18n.locale}/services#services-detail` }">Services</router-link>
              <router-link :to="{ path: `/${$i18n.locale}/services#services-detail` }">
                <ul>
                  <li>{{ $t('services-1-title') }}</li>
                  <li>{{ $t('services-2-title') }}</li>
                  <li>{{ $t('services-3-title') }}</li>
                  <li>{{ $t('services-4-title') }}</li>
                </ul>
              </router-link>
            </ul>
          </nav>
          <div class="footer-social">
            <div class="footer-social-title">{{ $t('footer-follow-us') }}</div>
            <div class="footer-social-links">
              <a href="https://facebook.com/anagraph.io" class="social-link" target="_blank">
                <img src="../assets/icons/facebook.svg" />
              </a>
              <a href="https://www.linkedin.com/company/anagraph" class="social-link" target="_blank">
                <img src="../assets/icons/linkedin.svg" />
              </a>
              <a href="https://twitter.com/anagraphgeo" class="social-link" target="_blank">
                <img src="../assets/icons/twitter.svg" />
              </a>
            </div>
            <div class="footer-social-lang">
              <locale-changer />
            </div>
          </div>
        </div>
        <div class="footer-copyright">
          Copyright Anagraph 2020
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LocaleChanger from './header/LocaleChanger'
export default {
  components: {
    LocaleChanger
  }
}
</script>

<style lang="scss">
footer {
  .footer {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-description {
      display: inline-flex;
      align-items: flex-start;
      width: 351px;
      &-text {
        margin-left: 37px;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 0.1px;
        color: #c2c2c8;
      }
    }
    &-links {
      display: inline-flex;
      justify-content: space-between;
      .footer-sitemap {
        ul {
          font-family: "geomanist-book";
          display: flex;
          flex-direction: column;
          list-style-type: none;
          line-height: 1.71;
          letter-spacing: 0.1px;
          margin: 0;
          padding: 0;
          color: #c2c2c8;
          a {
            font-family: "geomanist-bold";
            padding-bottom: 16px;
          }
        }
      }
      .footer-social {
        display: flex;
        flex-direction: column;
        &-title {
          font-family: "geomanist-bold";
          padding-bottom: 16px;
          font-size: 14px;
        }
        &-links {
          display: flex;
          padding-bottom: 16px;
          .social-link {
            width: 36px;
            height: 36px;
            margin-right: 10px;
            img {
              background-color: white;
              padding: 10px;
              border-radius: 50%;
              opacity: 0.2;
            }
          }
        }
        &-lang {
          justify-content: center;
          a {
            font-family: "geomanist-medium";
            font-size: 13px;
            color:#FFBA4E;
            cursor: pointer;
          }
        }
      }
    }
    &-copyright {
      font-size: 12px;
      color: #c2c2c8;
    }
  }
  @media (max-width: 1024px) {
    margin-left: 40px;
    .footer {
      &-description {
        display: none;
      }
      &-links {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
      }
      &-copyright {
        margin: 40px;
      }
    }
  }
  @media (min-width: 1024px) {
    padding: 52px 57px;
    .footer {
      width: 910px;
      &-links {
        width: 400px;
      }
    }
  }
  @media (min-width: 1440px) {
    padding: 55px 53px;
    .footer {
      width: 1326px;
    }
  }
}

</style>
