/* eslint-disable semi */
<template>
  <div class="about">
    <div class="vertical-title">{{ $t("header-about") }}</div>
    <section class="about-introduction">
      <h1 class="about-introduction-1" v-html="$t('about-introduction-1')"></h1>
      <p class="text-center about-introduction-2">
        {{ $t("about-introduction-2") }}
      </p>
      <p class="text-center about-introduction-3">
        {{ $t("about-introduction-3") }}
      </p>
      <p class="text-center about-introduction-4">
        {{ $t("about-introduction-4") }}
      </p>
    </section>
    <section class="about-technos">
      <div class="icon-links techno-links">
        <a v-for="item in technos" :key="item.name" :href="item.link">
          <img
            :src="require(`../assets/logo/technos/${item.image}`)"
            :alt="item.name"
          />
        </a>
      </div>
    </section>
    <section class="about-partners">
      <h2>{{ $t("about-partners") }}</h2>
      <div class="icon-links">
        <a
          v-for="item in partners"
          :key="item.name"
          :href="item.link"
          target="_blank"
        >
          <img
            :src="require(`../assets/logo/partners/${item.image}`)"
            :alt="item.name"
          />
        </a>
      </div>
    </section>
    <section class="about-team">
      <h2>{{ $t("about-team") }}</h2>
      <div class="about-team-tiles">
        <div class="tile" v-for="item in team" :key="item.id" :id="item.id">
          <img
            :src="require(`../assets/images/team/${item.image}`)"
            :alt="item.id"
          />
          <div class="description">
            <div class="name">{{ item.name }}</div>
            <div class="job">{{ $t(`job-${item.id}`) }}</div>
          </div>
        </div>
      </div>
    </section>
    <div class="vertical-text us" :class="{ fr: $i18n.locale === 'fr' }">
      {{ $t("vertical-us") }}
    </div>
    <div class="vertical-text team" :class="{ fr: $i18n.locale === 'fr' }">
      {{ $t("vertical-team") }}
    </div>
  </div>
</template>

<script>
import technos from '../data/technos'
import partners from '../data/partners'
import team from '../data/team'
export default {
  props: ['lang'],
  mounted () {
    if (this.lang) {
      this.$i18n.locale = this.lang
    }
  },
  data () {
    return {
      technos,
      partners,
      team
    }
  }
}
</script>

<style lang="scss">
.techno-links a {
  margin: 20px 30px;
}
.about {
  background-image: url("../assets/images/about-background-1v2.jpg"),
    url("../assets/images/about-background-2.png"),
    url("../assets/images/about-background-3.png"),
    url("../assets/images/about-background-4.png");
  @media (max-width: 1280px) {
    background-image: url("../assets/images/about-background-1-small.png"),
      url("../assets/images/about-background-2.png"),
      url("../assets/images/about-background-3.png"),
      url("../assets/images/about-background-4.png");
  }
  background-repeat: no-repeat;
  background-position: right top, left 1589px, 20px 1330px, left 290px;
  .vertical-title {
    top: 340px;
  }
  &-introduction {
    padding-top: 304px;
    &-1 {
      font-size: 72px;
      margin-bottom: 175px;
    }
    &-2 {
      max-width: 460px;
      font-size: 18px;
      line-height: 1.89;
      margin-bottom: 240px;
    }
    &-3 {
      font-family: "geomanist-bold";
      max-width: 643px;
      font-size: 44px;
      line-height: 1.14;
    }
    &-4 {
      max-width: 531px;
      font-size: 18px;
      line-height: 1.89;
      margin-bottom: 100px;
    }
  }
  &-technos {
    margin-bottom: 136px;
    &-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &-partners {
    margin-bottom: 136px;
    &-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &-team {
    &-tiles {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @media (max-width: 1280px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 780px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        .tile {
          max-width: 325px;
          margin-top: 48px;
        }
      }
      grid-gap: 56px 80px;
      .tile {
        display: flex;
        flex-direction: column;
        img {
          display: block;
          width: 100%;
          height: auto;
          filter: grayscale(100%);
        }
        .description {
          margin-top: 18px;
          font-size: 24px;
          line-height: 1.17;
          color: #ffba4e;
          .job {
            font-size: 18px;
            color: #63636b;
          }
        }
      }
    }
  }
}
.team {
  right: -380px;
  top: 3800px;
  z-index: -1;
  &.fr {
    right: -540px;
    top: 3950px;
  }
}
.us {
  right: -100px;
  top: 1000px;
  &.fr {
    right: -430px;
    top: 1330px;
  }
}

@media (max-width: 1024px) {
  .about {
    background-image: url("../assets/images/about-background-1-extra-small.png"),
      url("../assets/images/about-background-2.png"),
      url("../assets/images/about-background-3.png"),
      url("../assets/images/about-background-4.png");
    &-introduction {
      margin: 0 10%;
      text-align: left;
      padding-top: 200px;
      &-1 {
        font-size: 2.5rem;
        max-width: 325px;
        margin-bottom: 280px;
      }
      &-2 {
        font-size: 1rem;
        max-width: 325px;
        margin-bottom: 80px;
      }
      &-3 {
        font-family: "geomanist-medium";
        font-size: 2.25rem;
        line-height: 2.5rem;
        max-width: 325px;
      }
      &-4 {
        max-width: 300px;
      }
    }
    /* &-team{
      padding: 0 5rem;
    } */
  }
  .team {
    display: none;
  }
}
</style>
