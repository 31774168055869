<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-header">
          <div class="modal-close" @click="$emit('close');">
            <img src="../assets/icons/close.svg" alt @click="showMobileMenu = true;" />
          </div>
        </div>
        <div class="modal-body">
          <nav class="mobile" @click="$emit('close');">
            <div class="modal-links">
              <router-link :to="{ path: `/${$i18n.locale}` }">{{ $t('header-home') }}</router-link>
              <router-link :to="{ path: `/${$i18n.locale}/about` }">{{ $t('header-about') }}</router-link>
              <router-link :to="{ path: `/${$i18n.locale}/services` }">Services</router-link>
              <router-link :to="{ path: `/${$i18n.locale}/blog` }">Blog</router-link>
              <router-link :to="{ path: `/${$i18n.locale}/#contact` }">Contact</router-link>
              <locale-changer/>
            </div>
          </nav>
        </div>
        <!--
          <div class="modal-footer">
            Follow us
          </div>
        -->
      </div>
    </div>
  </transition>
</template>

<script>
import LocaleChanger from './header/LocaleChanger.vue'
export default {
  name: 'mobileMenu',
  components: {
    LocaleChanger
  }
}
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffba4e;
  display: table;
  transition: opacity 0.3s ease;
}

.modal-button {
  background-color: #ff5a50;
}

.modal-wrapper {
  padding: 28px 32px;
  margin: 0 auto;
}
.modal-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  font-family: "geomanist-bold";
  font-size: 14px;
}
$base-font-size: 16px;

@function px-to-rem($target, $context: $base-font-size) {
  @return ($target / $context) * 1rem;
}
.modal-body {
  .modal-links {
    padding: 52px 0;
    display: flex;
    flex-direction: column;
    font-family: "geomanist-bold";
    font-size: px-to-rem(34px);
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.07;
    letter-spacing: normal;
    text-align: center;
    a, a:visited {
      color: #20202c;
    }
  }
}

.modal-close {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  img {
    height: 22px;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
