<template>
  <div id="contact">
    <div id="contact-header">
      <h1 class="contact-title">{{ $t('home-contact-title') }}</h1>
      <p v-html="$t('home-contact-text')"></p>
    </div>
    <div id="contact-form">
      <form
        id="email-input-wrapper"
        action="https://eepurl.com/dumt8f"
        method="post"
        name="mc-embedded-subscribe-form"
        target="_blank"
        novalidate
      >
        <input
          id="email-input"
          type="email"
          name="EMAIL"
          :placeholder="$t('home-contact-email-placeholder')"
          required
        />
        <div style="position: absolute; left: -5000px;" aria-hidden="true">
          <input type="text" name="b_fe6ec1bc5cb0f149502b7ec51_f0402d324c" tabindex="-1" value />
        </div>
        <button type="submit" id="email-validate" value="Subscribe" src="../assets/icons/StrokeRight.png" alt />
        <img src="" alt="" >
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style lang="scss">
$base-font-size: 16px;

@function px-to-rem($target, $context: $base-font-size) {
  @return ($target / $context) * 1rem;
}
@media (max-width: 1024px) {
  #contact {
    padding-top: 120px;
    #contact-header {
      .contact-title {
        font-size: px-to-rem(56px);
        line-height: 0.89;
      }
    }
  }
}
@media (min-width: 1024px) {

  #contact {
    padding-top: 250px;
  }
  .button-mobile {
    display: none;
  }
}
#contact {
  margin: 0 auto;
  text-align: center;
  max-width: 1060px;
  color: #c2c2c8;
  padding-bottom: 170px;
}
#contact-header {
  text-align: center;
  .contact-title {
    font-family: "geomanist-ultra";
    font-size: px-to-rem(175px);
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #FFBA4E;
    text-transform: uppercase;
  }
}
#contact-form {
  display: flex;
  justify-content: center;
  #email-input-wrapper {
    height: 58px;
    border-radius: 29px;
    margin-top: 42px;
    background-color: rgba(42, 42, 53, 0.5);
    border: none;
    text-align: left;
    #email-input {
      padding-left: 23px;
      height: 100%;
      width: 280px;
      color: white;
      background-color: rgba(42, 42, 53, 0);
      border: none;
    }
    #email-validate {
      width: 16px;
      height: 16px;
      margin-right: 23px;
      border: none;
      /* margin: 0; */
      padding: 0;
      min-width: 0;
      background-image: url('../assets/icons/StrokeRight.png');
      background-repeat: no-repeat;
      display: inline-block;
      vertical-align: middle;
      background-color: rgba(42, 42, 53, 0);
    }
  }
  #email-input::placeholder {
    padding-left: 1rem;
  }
}
</style>
