<template>
  <div class="blog-page">
    <div class="blog">
      <div class="blog__header">
        <div class="blog__header__start">
          <div class="blog-title">
            <h1>Blog</h1>
          </div>
          <p class="blog-description">
            Réflexions, technologies et astuces — votre dose de nouveautés en
            géospatial. Abonnez-vous à notre infolettre pour ne rien manquer:
          </p>
        </div>
        <div class="blog__header__end">
          <form
            class="blog-form"
            action="https://eepurl.com/dumt8f"
            method="post"
            name="mc-embedded-subscribe-form"
            target="_blank"
            novalidate
          >
            <input
              id="email-input"
              type="email"
              name="EMAIL"
              :placeholder="$t('home-contact-email-placeholder')"
              required
            />
            <div style="position: absolute; left: -5000px;" aria-hidden="true">
              <input
                type="text"
                name="b_fe6ec1bc5cb0f149502b7ec51_f0402d324c"
                tabindex="-1"
                value
              />
            </div>
            <button type="submit" id="email-validate" value="Subscribe">
              Valider
            </button>
          </form>
          <div class="blog-select">
            <custom-select
              :options="[
                { value: null, label: 'Toutes Les catégories' },
                ...categories.map(({ id: value, name: label }) => ({
                  value,
                  label
                }))
              ]"
              v-model="currentCategory"
            />
          </div>
        </div>
      </div>
      <div class="blog__content">
        <a
          :href="`/${$i18n.locale}/articles/${item.slug}`"
          class="blog-card"
          v-for="(item, index) in visiblePosts"
          :key="index"
        >
          <div class="blog-card__img">
            <img :src="getImageUrl(item)" alt="" height="100%" />
          </div>
          <div class="blog-card__content">
            <div class="blog-card__content__category">
              {{ getCategory(item) }}
            </div>
            <div class="blog-card__content__title">
              <h3 v-html="item.title.rendered" />
            </div>
            <div class="blog-card__content__excerpt">
              <span v-html="item.excerpt.rendered"></span> ... more
            </div>
          </div>
        </a>
        <a
          :href="`/${$i18n.locale}/anagraph-hebdo`"
          class="blog-card"
        >
          <div class="blog-card__img">
            <img :src="'http://blog.anagraph.io/wp-content/uploads/2021/06/Anagraph-Hebdo.png'" alt="" height="100%" />
          </div>
          <div class="blog-card__content">
            <div class="blog-card__content__category">
              Misc
            </div>
            <div class="blog-card__content__title">
              <h3>Anagraph Hebdo</h3>
            </div>
            <div class="blog-card__content__excerpt">
              Chaque semaine, nos liens favoris
            </div>
          </div>
        </a>
      </div>

      <div class="blog__more">
        <div
          @click="limitedResults = false"
          v-if="posts.length > 5 && limitedResults"
          class="button-more"
        >
          afficher tout
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import posts from '@/blog-data/posts'
import media from '@/blog-data/media'
import categories from '@/blog-data/categories'
import CustomSelect from '@/components/select'
const ANAGRAPH_HEBDO_CATEGORY = 35
const CAREER_CATEGORY = 47
const PUBLISHED_CATEGORY = 42
const publishedBlogPosts = posts.filter(post => post.categories.includes(PUBLISHED_CATEGORY) && !post.categories.includes(ANAGRAPH_HEBDO_CATEGORY) && !post.categories.includes(CAREER_CATEGORY))
export default {
  data () {
    return {
      media,
      posts: publishedBlogPosts,
      currentCategory: null,
      categoryCollection: categories,
      limitedResults: true
    }
  },
  async mounted () {
    if (process.env.VUE_APP_PREVIEW) {
      const posts = await axios
        .get('https://blog.anagraph.io/wp-json/wp/v2/posts?per_page=100')
        .then(response => response.data)
      this.posts = posts.filter(post => !post.categories.includes(ANAGRAPH_HEBDO_CATEGORY) && !post.categories.includes(CAREER_CATEGORY))
      this.media = await axios
        .get('https://blog.anagraph.io/wp-json/wp/v2/media?per_page=100')
        .then(response => response.data)
      this.categoryCollection = await axios
        .get('https://blog.anagraph.io/wp-json/wp/v2/categories?per_page=100')
        .then(response => response.data)
    }
  },
  components: {
    CustomSelect
  },
  computed: {
    visiblePosts () {
      const posts = this.posts.filter(post =>
        this.currentCategory
          ? post.categories.includes(this.currentCategory)
          : true
      )
      return this.limitedResults ? posts.slice(0, 5) : posts
    },
    categories () {
      return this.categoryCollection.filter(
        c => c.slug !== 'en' && c.slug !== 'fr' && c.slug !== 'uncategorized' && c.slug !== 'anagraph-hebdo' && c.slug !== 'published'
      )
    },
    langCategoryIds () {
      return this.categoryCollection
        .filter(c => c.slug === 'en' || c.slug === 'fr')
        .map(c => c.id)
    }
  },
  methods: {
    getCategory (post) {
      const id = post.categories
        .filter(c => !this.langCategoryIds.includes(c) && ![ANAGRAPH_HEBDO_CATEGORY, PUBLISHED_CATEGORY].includes(c))
        .shift()
      const category = this.categories.find(c => c.id === id)
      return category ? category.name : ''
    },
    getImageUrl (post) {
      const featureImageId = post.featured_media
      const featureImage = this.media.find(item => item.id === featureImageId)
      const featureImageUrl = featureImage ? featureImage.guid.rendered : null
      return featureImageUrl
    }
  }
}
</script>

<style lang="scss">
.blog-select {
  cursor: pointer;
}
.button-more {
  cursor: pointer;
  margin-top: 90px;
  padding: 15px 48px;
  border-radius: 28.5px;
  box-shadow: 0 10px 34px -12px rgba(0, 0, 0, 0.15);
  background-color: #ffba4e;
  font-family: "geomanist-book";
}
.blog-page {
  width: 100%;
  background-color: #f4f4f4;
}
.blog {
  margin: auto;
  display: flex;
  padding: 100px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1023px) {
    padding: 0;
    padding-bottom: 60px;
  }
  color: black;
  h1,
  h2,
  h3,
  a {
    color: black;
  }

  &__header {
    width: 1060px;
    display: flex;
    flex-direction: column;
    margin-bottom: 89px;
    justify-content: center;
    @media (max-width: 1280px) {
      align-items: center;
      width: 100vw;
    }
    &__start {
      display: flex;
      justify-content: space-between;
      @media (max-width: 1280px) {
        flex-direction: column;
      }
      margin-bottom: 20px;
      .blog-title {
        height: 105px;
        h1 {
          font-family: "geomanist-medium";
          font-size: 82px;
          line-height: 1;
          color: #20202c;
        }
        @media (max-width: 1280px) {
            margin: 60px 0;
            height: 38px;
            h1 {
              font-size: 38px;
            }
          }
      }
      .blog-description {
        width: 500px;
        height: 144px;
        @media (max-width: 1280px) {
          width: 325px;
          height: 240px;
        }
        font-family: "geomanist-bold";
        font-size: 28px;
        line-height: 1.29;
        color: #20202c;
      }
    }
    &__end {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      @media (max-width: 1280px) {
        flex-direction: column;
        height: 100px;
      }
      .blog-form {
        width: 500px;
        @media (max-width: 1280px) {
          width: 325px;
        }
        display: flex;
        justify-content: space-between;
        input {
          height: 57px;
          padding: 12px 118px 10px 22px;
          @media (max-width: 1280px) {
            padding: 0;
            width: 100%;
          }
          border-radius: 10px;
          box-shadow: 0 10px 34px -12px rgba(0, 0, 0, 0.15);
          background-color: white;
          font-family: "geomanist-book";
          font-size: 18px;
          color: #8a8a90;
        }
        button {
          cursor: pointer;
          min-width: 0;
          width: 100px;
          height: 57px;
          padding: 11px 3px;
          border-radius: 10px;
          margin: 0;
          box-shadow: 0 10px 34px -12px rgba(0, 0, 0, 0.15);
          background-color: #20202c;
          font-family: "geomanist-book";
          font-size: 14px;
          line-height: 2.57;
          text-align: center;
          color: #ffba4e;
        }
      }
    }
  }
  &__content {
    max-width: 1080px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1280px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__more {
    display: flex;
    justify-content: center;
  }
}
.blog-card {
  width: 340px;
  margin: 10px;
  height: 450px;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 10px 34px -12px rgba(0, 0, 0, 0.15);
  @media (max-width: 1280px) {
    margin: 30px;
  }
  &__img {
    height: 200px;
    width: 340px;
    display: flex;
    justify-content: center;
  }
  &__content {
    h3 {
      font-family: "geomanist-medium";
      font-size: 22px;
      line-height: 1.09;
      color: #20202c;
    }
    margin: 20px 30px;
    &__category {
      font-family: "geomanist-book";
      margin-bottom: 16px;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 0.6px;
      color: #8a8a90;
    }
    &__title {
      height: 90px;
    }
    &__excerpt {
      display: inline-block;
      font-family: "geomanist-book";
      font-size: 14px;
      line-height: 1.71;
      color: #8a8a90;
      height: 70px;
      overflow: hidden;
    }
  }
}
</style>
