<template>
  <div class="autoselect" :class="{ open: selectBoxOpen }">
    <div class="autoselect__container" :class="{ open: selectBoxOpen }">
      <multiselect
        class="select"
        ref="select"
        v-model="value"
        @input="input"
        @close="closeSelectBox"
        :searchable="false"
        :clear-on-select="true"
        :options="options"
        placeholder="Toutes les catégories"
        label="label"
        track-by="label"
      ></multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  props: {
    initValue: {
      type: Object,
      default: null
    },
    options: {
      type: Array,
      default: () => [
        { label: 'tous', value: null },
        { label: 'few', value: 2 },
        { label: 'fow', value: 3 },
        { label: 'fqw', value: 4 },
        { label: 'biz', value: 5 },
        { label: 'fez', value: 9 },
        { label: 'fro', value: 10 },
        { label: 'fei', value: 12 },
        { label: 'jow', value: 32 },
        { label: 'bar', value: 4 }
      ]
    },
    nullValueLabel: {
      type: String,
      value: 'all'
    }
  },
  data () {
    return {
      value: this.initValue,
      selectBoxOpen: false
    }
  },
  methods: {
    async openSelectBox () {
      this.selectBoxOpen = true
      await this.$nextTick()
      this.$refs.select.$el.focus()
    },
    input (selected) {
      this.$emit('input', selected?.value)
    },
    closeSelectBox () {
      this.selectBoxOpen = false
    },
    nameWithLang ({ name, language }) {
      return `${name} — [${language}]`
    }
  }
}
</script>
<style lang="scss">
.autoselect {
  color: #20202c;
  font-family: "geomanist-book";
  font-size: 18px;
  width: 340px;
  box-shadow: 0 10px 50px -12px rgba(0, 0, 0, 0.32);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  :focus {
    outline: none;
  }
  &__container {
    background-color: white;
    position: absolute;
    width: 300px;
    /* color: #9898a8; */
    .multiselect.select {
      &:after {
        position: absolute;
        content: "";
        top: 16px;
        right: 20px;
        border: solid #ffba4e;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
      &.multiselect--active {
        background-color: white;
        overflow: hidden;
        &:after {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
          top: 20px;
          max-height: 100%;
        }
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;
        li {
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 57px;
          padding: 12px 17px;
          /* border:  solid 1px; */
          :hover {
            color: #5a5a65;
          }
          .multiselect__option {
            width: 100%;
            text-align: left;
          }
          .multiselect__option--selected {
            color: #5a5a65;
          }
        }
      }

      &__content-wrapper {
        overflow: scroll;
      }
      &__tags {
        display: flex;
        align-items: center;
        height: 57px;
        /* padding: 0 32px; */
      }
      &__input {
        outline: none;
        border: none;
        font-size: 16px;
        :focus {
          outline: none;
        }
      }
    }
    .multiselect__tags {
      height: 57px;
      padding: 12px 17px;
    }
  }
}
</style>
