import Vue from 'vue'
import Page from './Page.vue'
import router from './router'
import 'normalize.css'
import './assets/main.scss'
import i18n from './i18n'
import KonamiCode from 'vue-konami-code'
import team from './data/team.json'
Vue.config.productionTip = false

Vue.use(KonamiCode, {
  callback: function () {
    team.sort(() => Math.random() - 0.5)
    team.map((guy, index) => {
      document.querySelector(`#${guy.id}`).children[0].src = `/t/${index + 1}.png`
    })
  }
})

const root = new Vue({
  router,
  i18n,
  render: h => h(Page)
})

document.addEventListener('DOMContentLoaded', function () {
  root.$mount('#app')
})
